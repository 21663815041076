import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
export interface AuthState {
  errorMessage: string;
  errorCode: string;
  isForgotPasswordSuccess: boolean;
  isChangePasswordSuccess: boolean;
  isResetPasswordSuccess: boolean;
  accessToken: string;
  decodedToken: any;
  rememberMe: boolean;
  phoneNumber: string;
}

export const initialState: AuthState = {
  errorCode: null,
  errorMessage: null,
  isForgotPasswordSuccess: false,
  isChangePasswordSuccess: false,
  isResetPasswordSuccess: false,
  accessToken: null,
  decodedToken: null,
  rememberMe: true,
  phoneNumber: null
};

export const authFeature = createFeature({
  name: 'authState',
  reducer: createReducer(
    initialState,
    on(AuthActions.signIn, () => ({ ...initialState })),
    on(AuthActions.signInError, (state, { errorCode, errorMessage }) => ({ ...state, errorCode, errorMessage })),
    on(AuthActions.resetState, () => initialState),
    on(AuthActions.forgotPasswordSuccess, state => ({ ...state, isForgotPasswordSuccess: true })),
    on(AuthActions.changePasswordSuccess, state => ({ ...state, isChangePasswordSuccess: true })),
    on(AuthActions.resetPasswordSuccess, state => ({ ...state, isResetPasswordSuccess: true })),
    on(AuthActions.signInSuccess, (state, { accessToken, decodedToken, rememberMe }) => ({
      ...state,
      accessToken,
      decodedToken,
      errorCode: null,
      errorMessage: null,
      rememberMe
    })),
    on(AuthActions.signInWithTokenSuccess, (state, { accessToken, decodedToken }) => ({ ...state, accessToken, decodedToken })),
    on(AuthActions.signOut, () => ({ ...initialState })),
    on(AuthActions.lockSession, state => ({ ...state, accessToken: null, decodedToken: null })),
    on(AuthActions.setPhoneNumber, (state, { phoneNumber }) => ({
      ...state,
      phoneNumber: phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length)
    }))
  ),
  extraSelectors: ({ selectDecodedToken, selectAccessToken, selectPhoneNumber }) => {
    const twoFactorAuthEnabled = createSelector(selectDecodedToken, decodedToken =>
      JSON.parse(decodedToken?.twoFactorAuthEnabled ?? false)
    );
    const twoFactorAuthLogged = createSelector(selectDecodedToken, decodedToken => JSON.parse(decodedToken?.twoFactorAuthLogged ?? false));
    return {
      selectUserId: createSelector(selectDecodedToken, decodedToken => decodedToken?.user),
      isAuthenticated: createSelector(
        selectAccessToken,
        twoFactorAuthEnabled,
        twoFactorAuthLogged,
        (accesToken, twoFactorAuthEnabled, twoFactorAuthLogged) => {
          if (!accesToken) {
            return false;
          } else {
            if (twoFactorAuthEnabled) {
              return twoFactorAuthLogged;
            }
            return true;
          }
        }
      ),
      twoFactorAuthEnabled,
      twoFactorAuthLogged,
      twoFactorAuthValidCode: createSelector(selectDecodedToken, decodedToken => JSON.parse(decodedToken?.twoFactorAuthValidCode ?? false)),
      twoFactorAuthPhoneNumber: createSelector(
        selectDecodedToken,
        selectPhoneNumber,
        (decodedToken, selectPhoneNumber) => selectPhoneNumber ?? decodedToken?.twoFactorAuthPhoneNumber
      )
    };
  }
});

const { name, reducer, ...selectors } = authFeature;
export const fromAuth = selectors;
